/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

function SEO({
  description, lang, url, title, boat,
}) {
  ////console.log('i18n', i18n);

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${description}`}
      meta={[
        {
          name: 'language',
          content: 'es',
          lang: 'es',
        },
        {
          property: 'og:site_name',
          content: 'Boatjump',
        },
        {
          name: 'description',
          content: description,
        },
        {
          name: 'robots',
          content: 'index,follow',
        },
      ]}
      link={[
        {
          rel: 'canonical',
          href: url[lang],
        },
        {
          rel: 'alternate',
          hreflang: 'es',
          href: url.es,
        },
        {
          rel: 'alternate',
          hreflang: 'en',
          href: url.en,
        },
        {
          rel: 'alternate',
          hreflang: 'en_us',
          href: (url.en_us)? url.en_us : (url.en_US) ? url.en_US : url.en,
        },
        {
          rel: 'alternate',
          hreflang: 'fr',
          href: url.fr,
        },
        {
          rel: 'alternate',
          hreflang: 'de',
          href: url.de,
        },
      ]}
      script={[
        {
          type: 'application/ld+json',
          id: 'orgSchema',
          innerHTML: '{"@context":"http://schema.org","@type":"Organization","address":{"@type":"PostalAddress","addressLocality":"Valencia","addressRegion":"Provincia de Valencia","addressCountry":"España","postalCode":46024,"streetAddress":"Marina Real Juan Carlos I, Muelle de la Aduana s/n, Edificio Lanzadera"},"name":"Boatjump","telephone":"+34960627136","url":"https://www.boatjump.com/?locale=es","email":"mailto:info@boatjump.com","description":"Boatjump es la plataforma en línea más fácil y completa para alquilar barcos en todo el mundo. Con una base de datos de más de 9,000 embarcaciones, los clientes pueden buscar, comparar y reservar sus embarcaciones en solo 4 clics con las mejores tarifas disponibles.","logo":"https://assets.boatjump.com/static/images/logo-boatjump.png","sameAs":["https://www.facebook.com/boatjump/","https://twitter.com/boat_jump","https://www.instagram.com/boatjump_es/","https://www.linkedin.com/company/boatjump","https://www.youtube.com/channel/UCsu4t0_lwwzF5uvRBdOvHQQ/videos","https://www.boatjump.com/blog"],"memberOf":"ANEN - Asociación Nacional de Empresas Náuticas","sponsor":[{"@type":"Organization","name":"Angels Capital","url":"https://www.angelscapital.es"},{"@type":"Organization","name":"PC Componentes","url":"https://www.pccomponentes.com"},{"@type":"Organization","name":"Big Ban Angels","url":"http://www.bigbanangels.org"},{"@type":"Organization","name":"Metxa","url":"http://metxa.com"}],"taxID":"ES-B57957078"}',
        },
        {
          type: 'application/ld+json',
          id: 'searchSchema',
          innerHTML: `{
            "@context": "http://schema.org",
            "@type": "WebSite",
            "url": "http://www.boatjump.com",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "http://www.boatjump.com/es/query?q={search_term_string}",
              "query-input": "required name=search_term_string"
            }
          }`,
        },
        {
          type: 'application/ld+json',
          id: 'prodSchema',
          innerHTML: (boat) ? `
          "@context":"http://schema.org",
            "@type":"Product",
            "description":${boat.description},
            "name":${boat.description},
            "model":${boat.model},
            "image":[
            ${boat.boat_assets_image_video.map((src) => `"${src.gds_object_id}"`)}
            ],
            "additionalType":[
            "http://www.productontology.org/id/Boat",
            "http://www.productontology.org/id/Yacht_charter"
            ],
            "sku":"Ref: ${boat.id}",
            "offers":{
            "@type":"Offer",
            "priceCurrency":"EUR",
            "price":2815.0,
            "availability":"http://schema.org/InStock",
            "availableAtOrFrom":{
            "@type":"place",
            "name":"Muelle de la lonja",
            "address":"Muelle de la lonja, Palma de mallorca, Mallorca, Balearic Islands, Spain"
            },
            "seller":{
            "@type":"Organization",
            "name":"Boatjump"
            }
            },
          ` : '',
        },
        {
          type: 'application/ld+json',
          id: 'google-rich-snippet-structured-data',
          innerHTML: `{"@context":"http://schema.org/",
          "@type":"Store",
          "name": "Boatjump",
          "image":"http://ekomi.com/images/shoplogos/a33007b8da0aa09d1c9a2749b0979eb2dc963497.png",
          "aggregateRating":{
            "@type": "AggregateRating","ratingValue": "4.6","bestRating": "5","reviewCount": "487"}}`,
        },
      ]}
    />
  );
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
