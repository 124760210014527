import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';

const Breadcrumb = ({breadcrumb, style}) => (
  <div style={style}>
    {
      breadcrumb.map((page, i) => {
        if(breadcrumb.length > i+1){
          return (
            <span>
              <Link to={page.url}>{page.text}
              </Link>
              &nbsp;>&nbsp;
            </span>)
        }else{
          return(
            <span>
              {page.text}
            </span>
          )
        }
      })
    }
  </div>
);
  
Breadcrumb.propTypes = {
  breadcrumb: PropTypes.arrayOf(Object),
  style: PropTypes.object,
}

Breadcrumb.defaultProps = {
  breadcrumb: [{url: "/", text: "Home"}],
  style: {},
}

  export default Breadcrumb;