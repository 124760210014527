import { Menu } from 'antd';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import './header.css';

const { SubMenu } = Menu;

const Header = (props) => {
  // //console.log(props);
  const { special } = props;


  // console.log(special);
  switch (props.page) {
    case 'boatDetail':
      return (
        <header {...props} className={`HeaderContainer HeaderContainerBoatDetail ${props.scrollclass}`}>
          <Link to={props.link} className="HeaderContainerLogoBoatDetail" />
          <div className="HeaderContainerTextContainer">
            {/* <Link to="/page-2" className="HeaderContainerText HeaderContainerTextContainerText">¿Cómo funciona?</Link>
            <Link to="/" className="HeaderContainerText HeaderContainerTextContainerText">Sobre nosotros</Link>
            <Link to="/" className="HeaderContainerText HeaderContainerTextContainerText">Blog</Link>
            <Link to="/" className="HeaderContainerText HeaderContainerTextContainerText">FAQ</Link> */}
            <Menu style={{ width: '100%' }} mode="horizontal">
              {
                (special)
                  ? special.map((menu) => {
                    if (menu.tags[0][0].name.indexOf('/') !== -1) {
                      // console.log('MENU', menu);
                      return (
                        <SubMenu
                          key="sub1"
                          title={<span>{menu.tags[0][0].name.split('/')[1]}</span>}
                        >
                          <Menu.ItemGroup>
                            {menu.post.map((p) => <Menu.Item key={`${p.slug}`}><Link to={`/es/${p.slug}/`}>{p.title.rendered}</Link></Menu.Item>)}
                          </Menu.ItemGroup>
                        </SubMenu>
                      );
                    }
                    return (
                      <Menu.Item
                        key={`${menu.post.slug}`}
                      >
                        <Link to={`/es/${menu.post.slug}`}>{menu.post.title.rendered}</Link>
                      </Menu.Item>
                    );
                  }) : ''
              }
            </Menu>
          </div>
          <Link to="/" className="HeaderContainerText HeaderContainerPhoneText" />
        </header>
      );
    case 'search':
      return (
        <header {...props} className={`HeaderContainer HeaderContainerSearch ${props.scrollclass}`}>
          <Link to={props.link} className="HeaderContainerLogoSearch" />
          <div className="HeaderContainerTextContainer">
            {/* <Link to="/page-2" className="HeaderContainerText HeaderContainerTextContainerText">¿Cómo funciona?</Link>
            <Link to="/" className="HeaderContainerText HeaderContainerTextContainerText">Sobre nosotros</Link>
            <Link to="/" className="HeaderContainerText HeaderContainerTextContainerText">Blog</Link>
            <Link to="/" className="HeaderContainerText HeaderContainerTextContainerText">FAQ</Link> */}
            <Menu style={{ width: '100%' }} mode="horizontal">
              {
                (special)
                  ? special.map((menu) => {
                    if (menu.tags[0][0].name.indexOf('/') !== -1) {
                      // console.log('MENU', menu);
                      return (
                        <SubMenu
                          key="sub1"
                          title={<span>{menu.tags[0][0].name.split('/')[1]}</span>}
                        >
                          <Menu.ItemGroup>
                            {menu.post.map((p) => <Menu.Item key={`${p.slug}`}><Link to={`/es/${p.slug}/`}>{p.title.rendered}</Link></Menu.Item>)}
                          </Menu.ItemGroup>
                        </SubMenu>
                      );
                    }
                    return (
                      <Menu.Item
                        key={`${menu.post.slug}`}
                      >
                        <Link to={`/es/${menu.post.slug}`}>{menu.post.title.rendered}</Link>
                      </Menu.Item>
                    );
                  }) : ''
              }
            </Menu>
          </div>
          <Link to="/" className="HeaderContainerText HeaderContainerPhoneText" />
        </header>
      );
    case 'searchMobile':
      return (
        <div className={`HeaderContainerSearchMobile HeaderContainerSearch ${props.scrollclass}`}>
          <div onClick={props.onClick} to={props.link} className="HeaderContainerLogoSearchMobile" />
          <Link to="/" className="HeaderContainerText HeaderContainerPhoneText" />
        </div>
      );
    default:
      return (
        <header {...props} className="HeaderContainer">
          <div className="HeaderContainerLogoLanding" />
          <div className="HeaderContainerTextContainerLanding">
            {/* <Link to="/" to="/page-2" className="HeaderContainerTextLanding HeaderContainerTextContainerText">¿Cómo funciona?</Link>
            <Link to="/" className="HeaderContainerTextLanding HeaderContainerTextContainerText">Sobre nosotros</Link>
            <Link to="/" className="HeaderContainerTextLanding HeaderContainerTextContainerText">Blog</Link>
            <Link to="/" className="HeaderContainerTextLanding HeaderContainerTextContainerText">FAQ</Link>
            {
              (special) ? special.map((category) => (<Link to="/" className="HeaderContainerTextLanding HeaderContainerTextContainerText">{category.name}</Link>)) : ''
            } */}
            <Menu style={{ width: '100%' }} mode="horizontal">
              {
                (special)
                  ? special.map((menu) => {
                    if (menu.tags[0][0].name.indexOf('/') !== -1) {
                      // console.log('MENU', menu);
                      return (
                        <SubMenu
                          key="sub1"
                          title={<span>{menu.tags[0][0].name.split('/')[1]}</span>}
                        >
                          <Menu.ItemGroup>
                            {menu.post.map((p) => <Menu.Item key={`${p.slug}`}><Link to={`/es/${p.slug}/`}>{p.title.rendered}</Link></Menu.Item>)}
                          </Menu.ItemGroup>
                        </SubMenu>
                      );
                    }
                    return (
                      <Menu.Item
                        key={`${menu.post.slug}`}
                      >
                        <Link to={`/es/${menu.post.slug}/`}>{menu.post.title.rendered}</Link>
                      </Menu.Item>
                    );
                  }) : ''
              }
            </Menu>
          </div>
          <Link to="/" className="HeaderContainerTextLanding HeaderContainerPhoneText" />
        </header>
      );
  }
};

Header.propTypes = {
  page: PropTypes.string,
  scrollclass: PropTypes.string,
  link: PropTypes.string,
};

Header.defaultProps = {
  page: 'landing',
  scrollclass: '',
  link: '/',
};

export default Header;
